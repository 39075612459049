import googleLibphonenumber from 'google-libphonenumber'
const { PhoneNumberUtil, PhoneNumber, PhoneNumberFormat } = googleLibphonenumber

export class PhoneNumberModel {
  public readonly value: string

  constructor(phoneNumber: string) {
    const hyphen = /[-˗ᅳ᭸‐‑‒–—―⁃⁻−▬─━➖ーㅡ﹘﹣－ｰ𐄐𐆑 ]/g
    const value = phoneNumber
      .replace(hyphen, '')
      .replace(/(\s)+/g, '')
      .replace(
        /[０-９]/g,
        (s) => String.fromCharCode(s.charCodeAt(0) - 0xFEE0))
    if (value.length < 10 || value.length > 11)
      throw new Error(`invalid phone number: ${value}`)
    this.value = value
  }
  public toJSON(): string {
    return this.value
  }
  public toString() {
    return this.value
  }
  public format(): string {
    const region = 'JP'
    const util = PhoneNumberUtil.getInstance()
    const number = util.parseAndKeepRawInput(this.value, region)
    if (!util.isValidNumberForRegion(number, region)) {
      return this.value
    }
    return util.format(number, PhoneNumberFormat.NATIONAL)
  }
  public static format(value: string) {
    const region = 'JP'
    const util = PhoneNumberUtil.getInstance()
    const number = util.parseAndKeepRawInput(value, region)
    if (!util.isValidNumberForRegion(number, region)) {
      return value
    }
    return util.format(number, PhoneNumberFormat.NATIONAL)
  }
}
